(<template>
  <main class="upgrade-page js-upgrade-main-cont">
    <div :class="{'in-progress': progressActive}"
         class="upgrade-page__content-wrapper is-progress-bar">
      <header class="upgrade-page__header">
        <router-link class="upgrade-page__logo-link"
                     :to="$makeRoute({name: 'BuyerHome'})" />
      </header>
      <section class="upgrade-page__content-section">
        <pros-list />
      </section>
      <section class="upgrade-page__content-section">
        <info-form @formstarted="progressActive = !progressActive"
                   @formcompleted="progressActive = !progressActive" />
      </section>
    </div>
  </main>
</template>)

<script>
  import ProsList from '@/components/enterprise/upgrade/ProsList';
  import EnterpriseInfoForm from '@/components/enterprise/EnterpriseInfoForm';

  export default {
    components: {
      'pros-list': ProsList,
      'info-form': EnterpriseInfoForm
    },
    data() {
      return {
        progressActive: false
      };
    }
  };
</script>

<style scoped>
  .upgrade-page {
    display: flex;
    flex-direction: column;
    min-height: 100%;
    padding: 15px;
  }

  .upgrade-page__logo-link {
    display: block;
    width: 148px;
    height: 45px;
    background-image: url(~@assets/imgs/logos/enterprise_logo.svg);
    background-image: var(--image-enterprise-logo);
    background-position: 50% 50%;
    background-size: 100% auto;
    background-repeat: no-repeat;
  }

  .upgrade-page__content-wrapper {
    display: flex;
    flex-grow: 1;
    flex-wrap: wrap;
    width: 100%;
    max-width: 850px;
    margin: 0 auto;
    background-color: #fff;
    box-shadow: 0 0 9px 0 rgba(0, 34, 102, 0.3);
  }

  .upgrade-page__content-section {
    flex-shrink: 0;
    width: 50%;
    min-height: 100%;
    padding: 0 10px 20px 10px;
  }

  .upgrade-page__content-section:first-child {
    padding-left: 2.5%;
  }

  .upgrade-page__content-section:last-child {
    padding-right: 2.5%;
  }

  .upgrade-page__header {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 7vh;
    min-height: 87px;
    max-height: 123px;
  }

  .upgrade-page__logo {
    display: block;
    width: 148px;
    height: 45px;
  }

  @media (max-width: 767px) {
    .upgrade-page__content-section {
      width: 100%;
    }
  }
</style>
