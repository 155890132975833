// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@assets/imgs/undefined_imgs/success_icon.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "\n.upgrade-descr__list[data-v-df8f1654] {\n  display: block;\n  width: 100%;\n  max-width: 370px;\n  margin: 0 auto;\n  margin-top: 40px;\n  padding: 0;\n  list-style-type: none;\n}\n@media (max-width: 768px) {\n.upgrade-descr__list[data-v-df8f1654] {\n    max-width: none;\n}\n}\n.upgrade-descr__list-item[data-v-df8f1654] {\n  display: block;\n  width: 100%;\n  min-height: 30px;\n  padding-left: 45px;\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-image: var(--image-success-icon);\n  background-position: 0 0;\n  background-size: 30px 30px;\n  background-repeat: no-repeat;\n  font-size: 16px;\n  line-height: 30px;\n}\n.upgrade-descr__list-item[data-v-df8f1654]:not(:last-child) {\n  margin-bottom: 18px;\n}\n", ""]);
// Exports
module.exports = exports;
